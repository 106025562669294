<template>
  <div id="bg">
      <el-row>
        <el-col :span="4">标题:</el-col>
        <el-col :span="20"><el-input type="textarea"  v-model="dialogArr.title" :autosize="{ minRows: 3, maxRows: 4}" placeholder="视频标题" size="small"></el-input></el-col>
      </el-row>
      <el-row>
        <el-col :span="4">账号:</el-col>
        <el-col :span="10">
          <el-input v-model="dialogArr.username" :disabled="usegw" placeholder="账号" size="small"></el-input>
        </el-col>
        <el-col :span="10">
          <el-checkbox v-model="usegw" >使用官方账号</el-checkbox>
        </el-col>
      </el-row>
<!--    <el-row>-->
<!--      <el-col>-->
<!--        <button @click="showProcess(0)">区域</button>-->
<!--        <button @click="showProcess(1)">进度条</button>-->
<!--        <button @click="showProcess(2)">视频</button>-->
<!--        <button @click="videoUploadPercent=100">100</button>-->
<!--      </el-col>-->
<!--    </el-row>-->
      <el-row>
        <el-col :span="4">
          <el-upload
              class="avatar-uploader"
              v-loading.fullscreen.lock="fullscreenLoading"
              action="#"
              v-bind:data="{FoldPath:'上传目录',SecretKey:'安全验证'}"
              v-bind:before-upload="beforeUploadVideo"
              :http-request="uploadVideo"
              v-bind:show-file-list="false">
            <el-button size="small" type="primary" class="el-icon-upload">视频上传</el-button>
          </el-upload>
        </el-col>
        <el-col :span="8">
          <div class="upload-video">
            <div class="avatar" id="short_video">

            </div>
            <i class="el-icon-plus avatar-uploader-icon" v-show="showProcessType==0" >预览区域</i>
            <el-progress type="circle"
                         id="elprocess"
                         v-bind:percentage="videoUploadPercent"
                         style="margin-top:7px;width:126px"></el-progress>
          </div>
        </el-col>
        <el-col :span="6">
          <div>时长:{{getDuration(videoForm.duration)}}</div>
          <div>大小:{{getSize(videoForm.size)}}</div>
          <div>尺寸:{{cicun}}</div>
        </el-col>
        <el-col :span="6">
          <div>封面图</div>
          <div><ximg :src="videoForm.img_url" alt="" :width="150"></ximg></div>
        </el-col>
      </el-row>
      <el-row v-if="dialogTitle == '视频添加'">
        <el-col :span="4">审核:</el-col>
        <el-col :span="20">
          <el-checkbox v-model="dialogArr.verify">通过</el-checkbox>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">点赞次数:</el-col>
        <el-col :span="20">
          <el-input type="number" v-model="dialogArr.goods_count" placeholder="点赞次数" size="small"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">观看次数:</el-col>
        <el-col :span="20">
          <el-input type="number" v-model="dialogArr.watch_count" placeholder="观看次数" size="small"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4">标签:</el-col>
        <el-col :span="20">
          <el-select v-model="dialogArr.types" multiple placeholder="请选择">
            <el-option
                v-for="item in typeList"
                :key="item.id"
                :label="item.title"
                :value="item.id">
            </el-option>
          </el-select>
        </el-col>
      </el-row>
      <el-row >
        <el-col :span="4"></el-col>
        <el-col :span="20" align="center">
          <el-button type="primary" @click="goToList()">取消</el-button>
          <el-button type="primary" @click="add">上传</el-button>
        </el-col>
      </el-row>
    <video src="" id="hidden_video" style="position: relative; left: -9999px">您的浏览器不支持视频</video>
  </div>
</template>

<script>
import {list as tagList} from "@/api/system/tagList"
import {add} from "@/api/short_video/short_video.js"
import SparkMD5 from "spark-md5"
import axios from "axios";
// import 'video.js/dist/video-js.css'
// import Videojs from 'video.js'
// import 'videojs-contrib-hls'
import CryptoJS from "crypto-js"
export default {
  name: "shortVideoList",
  components: {
  },
  data() {
    return {
      showProcessType:0,
      typeList: [],
      dialogAdd: false, //添加弹窗
      fullscreenLoading:false,
      dialogTitle: "添加视频",
      isShowVideo:false, // 查看视频
      usegw:false,
      dialogArr: {
        id:0,
        title: "", //标题
        username:"",
        types: [], //分类
        verify: false,
        watch_count: 0, // 观看次数
        goods_count: 0, // 点赞次数
        url:"",
      }, //弹窗编辑数组
      videoFlag: false,
      //是否显示进度条
      videoUploadPercent: "",
      //进度条的进度，
      isShowUploadVideo: false,
      //显示上传按钮
      videoForm: {
        showVideoPath: '',
        size:0, // 上传到后台的大小
        duration: 0 , // 上传到后台的秒数
        img_url:"", // 封面图
        file: null,
        filename:"", // 实际存储到数据库的路径
        path: "", //CDN的路径
        pic_url: "", // 实际存储到数据库的封面图
      },
      max_duration: 6 * 60, // 最大时间是6分钟
      max_size: 800 * 1024 * 1024,
      upload_video:null,
      per_slice:1024*1024,
      videoSetting:{
        autoplay:false,
        controls:true,
        bigPlayButton:false,
      },
      videoElementHtml:"",
      cicun:"",
    };

  },
  computed: {},
  created() {
  },
  mounted() {
    this.getType();
    this.genVideo("")
    this.showProcess(0)
  },
  unmounted(){
  },
  methods: {
    goToList(){
      this.$router.push({name:'短视频列表'})
    },
    genVideo(url){
      this.cicun = ""
      document.getElementById("short_video").innerHTML = ""
      var videoElement = document.createElement("video")
      videoElement.id = "short-video-element"
      videoElement.width = 200;
      videoElement.autoplay = true
      videoElement.loop = true
      videoElement.muted = true
      videoElement.controls = true
      var self = this
      this.videoForm.img_url = ""
      videoElement.addEventListener("canplay",function (e){
        self.videoForm.duration = e.target.duration
        if (parseInt(e.target.duration) > self.max_duration){
          return;
        }
        // 开始截图
        var canvas = document.createElement("canvas")
        canvas.width = videoElement.videoWidth;
        // videoElement.videoWidth
        // canvas.height = videoElement.videoHeight * canvas.width / videoElement.videoWidth
        canvas.height = videoElement.videoHeight
        self.cicun = canvas.width + "X"+canvas.height
        canvas.getContext("2d").drawImage(videoElement , 0, 0, canvas.width, canvas.height);
        self.videoForm.img_url = canvas.toDataURL("image/png");
      })
      videoElement.src = url
      document.getElementById("short_video").appendChild(videoElement)
    },
    base64ToFile(urldata,filename){
      let arr = urldata.split(",");
      let mine = arr[0].match(/:(.*?);/)[1];
      let bytes = atob(arr[1]);// 解码base64
      let n = bytes.length
      let ia = new Uint8Array(n);
      while (n--){
        ia[n] = bytes.charCodeAt(n);
      }
      return new File([ia],filename,{type:mine})
    },
    //上传前回调
    beforeUploadVideo(file) {
      this.showProcess(0)
      this.videoForm.file = file; // 文件存储起来,上传成功才给播放
      if (['video/mp4'].indexOf(file.type) == -1) {
        //  'video/ogg', 'video/flv', 'video/wmv', 'video/rmvb', 'video/avi',,'video/mov'
        this.$message({ message: "暂不支持"+file.type+"格式!", type: "error" });
        return false;
      }
      // 800M
      if(file.size > this.max_size ){
        this.$message({ message: "视频不能超过800M", type: "error" });
        return false
      }
      var url = URL.createObjectURL(this.videoForm.file);
      this.genVideo(url)
      this.videoForm.showVideoPath = url
      this.videoForm.size = this.videoForm.file.size
      this.isShowUploadVideo = false;
    },
    getDuration(value){
      let result = parseInt(value)
      let h = Math.floor(result / 3600) < 10 ? '0' + Math.floor(result / 3600) : Math.floor(result / 3600);
      let m = Math.floor((result / 60 % 60)) < 10 ? '0' + Math.floor((result / 60 % 60)) : Math.floor((result / 60 % 60));
      let s = Math.floor((result % 60)) < 10 ? '0' + Math.floor((result % 60)) : Math.floor((result % 60));
      let res = '';
      if(h !== '00' ) res += `${h}.`;
      if(m !== '00' || h !== '00') res += `${m}.`;
      res += `${s}`;
      return res;
    },
    getSize(size){
      return (size / this.per_slice).toFixed(2) + "M"
    },
    //上传成功回调
    handleVideoSuccess(res) {
      this.showProcess(2)
      this.uploadPic(this.base64ToFile(this.videoForm.img_url,(new Date()).toTimeString() + ".png")) //  开始上传图片
      this.videoForm.filename = res.filename
      var list = this.videoForm.file.name.split(".")
      this.dialogArr.title = list[0]
      this.videoFlag = false;
    },
    getFileMd5(spark,file,currentIndex,callback){
      var reader = new FileReader()
      var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      var start = currentIndex
      var end = currentIndex + this.per_slice
      if (end >= file.size){
        end = file.size
      }
      var self = this
      reader.readAsArrayBuffer(blobSlice.call(file,start,end))
      reader.onload = function (e){
        spark.append(e.target.result); // append array buffer
        if (end < file.size){
          self.getFileMd5(spark,file,end,callback)
        }else{
          spark.append(file.name);
          spark.append(file.size);
          callback({
            file:file,
            size:file.size,
            hash:spark.end(),
          })
        }
      }
    },
    postVideoSlice(file,progress,headers,_timeout) {
      let timeout = 60;
      if (_timeout !== undefined){
        timeout = _timeout
      }
      var _headers = {
        "Content-Type":'multipart/form-data;charset-utf-8',
        "Name":"short_video",
        "is-encode":"true",
        "Token":localStorage.getItem("token"),
        "Uid":localStorage.getItem("uid"),
      }
      for(var key in headers){
        _headers[key] = headers[key]
      }
      axios.defaults.timeout = 1000 * timeout;
      return new Promise((resolve, reject) => {
        let data = new FormData();
        let upload_url = localStorage.getItem("uploader_api"); // 视频上传地址
        data.append('uploadfile',file)
        data.append("ac","uploadFile")
        axios.post(upload_url+"/uploadFile", data,{
          headers:_headers,
          onUploadProgress:(event)=>{
            if (progress !== undefined && typeof progress === "function"){
              progress(event)
            }
          },
        }).then(res => {
          resolve(res.data);
        }).catch(err => {
          reject(err.data)
        })
      });
    },
    startUpload(video,hInfo,slice_start,suc,err,loading){
      loading(slice_start)
      var blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice
      var self = this
      if (slice_start >= video.size){
        return
      }
      var end = slice_start + this.per_slice
      if(end >= video.size){
        end = video.size
      }
      var spark = new SparkMD5.ArrayBuffer()
      var reader = new FileReader()
      reader.readAsArrayBuffer(blobSlice.call(video.file,slice_start,end))
      reader.onload = function (e){
        spark.append(e.target.result); // append array buffer
        var slice_md5 = spark.end()
        // 检测是否已经上传过了，如果已经上传过了，那么久跳过上传 根据hash值检测
        var isPass = false
        for(var i in hInfo.slice){
          if (hInfo.slice[i].hash == slice_md5){
            isPass = true
            break
          }
        }
        if(isPass){
          self.startUpload(video,hInfo,end,suc,err,loading)
          return
        }
        var list = video.file.name.split(".")
        self.postVideoSlice(blobSlice.call(video.file, slice_start, end),(e)=>{
          loading(slice_start+e.loaded-10)
        },{
          'SLICE-UPLOAD': 'true',
          'IS-BACKEND': 'true', // 是否是后台，默认不是后台
          'SLICE-HASH': slice_md5,
          'VIDEO-HASH': video.hash,
          'VIDEO-SIZE': video.size,
          'VIDEO-EXT': list[1],
        },120).then((resp)=>{
          if (resp.code == 0){
            if (resp.data.done){
              suc(resp.data)
            }else{
              self.startUpload(video,hInfo,end,suc,err,loading)
            }
          }else{
            err(resp.msg)
          }
        },(res)=>{
          err(res)
        })
      }
    },
    getHash(hasher,file,currentChunk,callback){
      var reader = new FileReader(), self = this;
      var blobSlice = File.prototype.mozSlice || File.prototype.webkitSlice || File.prototype.slice;
      var chunkSize = this.per_slice;
      var chunks = Math.ceil(file.size / chunkSize);
      var start = currentChunk * chunkSize;
      var end = start + chunkSize >= file.size ? file.size : start + chunkSize;
      reader.readAsArrayBuffer(blobSlice.call(file, start, end));
      reader.onload = function (evt) {
        var fileStr = evt.target.result;
        var tmpWordArray = self.arrayBufferToWordArray(fileStr);
        hasher.update(tmpWordArray);
        currentChunk += 1;
        fileStr = null;
        tmpWordArray = null;
        if (currentChunk < chunks) {
          self.getHash(hasher,file,currentChunk,callback)
        }else{
          callback({
            hash:hasher.finalize().toString(),
            file:file,
            size:file.size,
          })
        }
      }
    },
    arrayBufferToWordArray(ab) {
      var i8a = new Uint8Array(ab);
      var a = [];
      for (var i = 0; i < i8a.length; i += 4) {
        a.push(i8a[i] << 24 | i8a[i + 1] << 16 | i8a[i + 2] << 8 | i8a[i + 3]);
      }
      return CryptoJS.lib.WordArray.create(a, i8a.length);
    },
    uploadVideoBySlice(file){
      this.showProcess(1)
      this.videoFlag = true;
      this.videoUploadPercent = 0;
      this.getHash(CryptoJS.algo.SHA256.create(),file,0,(hInfo)=>{
      // this.getFileMd5(new SparkMD5(),file,0,(hInfo)=>{
        this.getHashInfo(hInfo,(res)=>{
          if (res.code > 0){
            this.$message({ message: res.msg, type: "success" });
            return;
          }
          if (res.data.filename != ""){
            // 表示已经上传过了.不用再次上传了
            this.videoUploadPercent = 100
            this.handleVideoSuccess(res)
            this.$message({ message: "视频上传成功", type: "success" });
          }else{
            // 开始分片上传到服务器
            this.startUpload(hInfo,res.data.video,0,(res)=>{
              if (res.done){
                // 上传成功回到这里
                this.videoUploadPercent = 100
                this.handleVideoSuccess(res)
                this.$message({ message: "视频上传成功", type: "success" });
              }
            },(res)=>{
              this.videoFlag = false;
              this.videoUploadPercent = 0
              this.$message({ message: "视频上传失败:"+res, type: "error" });
            },(start)=>{
              this.videoUploadPercent = Number((start / hInfo.size * 100) >= 99?99:start / hInfo.size * 100).toFixed(2)
            })
          }
        })
      })
    },
    getHashInfo(hInfo,callback){
      var list = hInfo.file.name.split(".")
      var xhr = new XMLHttpRequest ();
      var data = {
        "ac":"getHashInfo",
        "hash":hInfo.hash,
        "size":hInfo.size,
        "ext":list[1],
        "uid":localStorage.getItem("uid"),
        "token":localStorage.getItem("token"),
      }
      var self = this
      let upload_url = localStorage.getItem("uploader_api");
      xhr.open("POST",upload_url+"/request",true)
      xhr.setRequestHeader('Token', localStorage.getItem("token"));
      xhr.setRequestHeader('Uid', localStorage.getItem("uid"));
      xhr.setRequestHeader('Name',"short_video");
      xhr.setRequestHeader('Content-type', 'application/json');
      xhr.onreadystatechange = function () {  //绑定响应状态事件监听函数
        if (xhr.readyState == 4) {  //监听readyState状态
          if (xhr.status == 200 || xhr.status == 0) {  //监听HTTP状态码
            try {
              callback(JSON.parse(xhr.responseText))
            }catch (e){
              self.$message({message: "视频上传失败:"+e.toString(), type: "error"})
            }
          }
        }
      }
      xhr.send(JSON.stringify(data))
    },
    uploadVideo(param){
      this.videoFlag = true;
      if (param.file.size > this.max_size) {
        this.$message({ message: "视频不能超过800M", type: "error" });
        return;
      }
      // 检测时长是否符合要求
      setTimeout(()=>{
        if(this.videoForm.duration > this.max_duration){
          this.$message({ message: "视频不能超过360秒", type: "error" });
          return;
        }
        this.uploadVideoBySlice(param.file)
      },500)
    },
    uploadPic(file){
      this.$common.showLoading("正在上传封面图!")
      this.postVideoSlice(file,()=>{},{},60).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0) {
          this.videoForm.pic_url = res.data.filename;
          this.$message({ message: "封面图上传成功", type: "success" });
        } else {
          this.$message({ message: res.msg, type: "error" });
        }
      },(e)=>{
        this.$common.hideLoading()
        this.$message({ message: e, type: "error" });
      })
    },
    add(){
      if (this.videoFlag){
        this.$message({ message: "视频正在上传中,请稍等!", type: "error" })
        return
      }
      if (this.videoForm.pic_url == ""){
        this.$message({ message: "封面图上传中,请稍等!", type: "error" })
        return
      }
      if (parseInt(this.videoForm.duration) > this.max_duration){
        this.$message({ message: "视频不能超过360秒!", type: "error" })
        return;
      }
      if (this.videoForm.size > this.max_size){
        this.$message({ message: "视频不能超过800M!", type: "error" })
        return;
      }
      if (this.videoForm.filename == ""){
        this.$message({ message: "视频上传中请稍后,或则刷新界面重新上传!", type: "error" })
        return;
      }
      this.$common.showLoading("正在添加短视频,请稍后!")
      add([
        {key:"title",val:this.dialogArr.title},
        {key:"username",val:this.dialogArr.username},
        {key:"url",val:this.videoForm.filename},
        {key:"pic_url",val:this.videoForm.pic_url},
        {key:"size",val:this.videoForm.size},
        {key:"duration",val:parseInt(this.videoForm.duration)},
        {key:"types",val:this.dialogArr.types.join(",")},
        {key:"watch_count",val:this.dialogArr.watch_count},
        {key:"goods_count",val:this.dialogArr.goods_count},
        {key:"verify",val:this.dialogArr.verify?1:0},
        {key:"usegw",val:this.usegw},
      ]).then((res)=>{
        this.$common.hideLoading()
        if(res.code == 0){
          this.dialogAdd = false
          this.$message({ message: "添加成功", type: "success" })
          this.goToList()
        }else{
          this.$message({ message: res.msg, type: "error" })
        }
      },(err)=>{
        console.log(err)
        this.$common.hideLoading()
      })
    },
    getTypeBy(id){
      for(var i in this.typeList){
        if(this.typeList[i].id == id){
          return this.typeList[i]
        }
      }
      return null
    },
    getType(){
      tagList().then((res)=>{
        this.typeList = res.data.list
      })
    },
    closeVideojs(){
      document.getElementById("short_video").style.display="none";
    },
    showVideojs(){
      document.getElementById("short_video").style.display="revert";
    },
    showProcess(type){
      // 显示视频上传进度条
      this.showProcessType = type
      if(type == 0) {
        // 显示预览区域
        document.getElementById("short_video").style.display = "none";
        document.getElementById("elprocess").style.display = "none";
      }else if(type == 1) {
        // 显示进度条
        document.getElementById("short_video").style.display = "none";
        document.getElementById("elprocess").style.display = "revert";
      }else{
          // 显示视频插件
          document.getElementById("short_video").style.display="revert";
          document.getElementById("elprocess").style.display="none";
      }
    }
  },
};
</script>

<style scoped>
.width_80{
  width: 80px !important;
}
.width_100{
  width: 100px !important;
}
.width_120{
  width: 120px !important;
}
.width_150{
  width: 150px !important;
}
.width_200{
  width: 200px !important;
}
.width_250{
  width: 250px !important;
}
.el-row{
  margin-bottom: 20px;
}
.el-row .el-col:first-child{
  text-align: right;
  padding-right: 10px;
  box-sizing: border-box;
}
.upload-video{ width: 200px; height: 200px;}
/* 图片上传部分 */
.el-row >>> .avatar-uploader .el-upload {
  /*border: 1px dashed #d9d9d9;*/
  /*border-radius: 6px;*/
  /*cursor: pointer;*/
  /*position: relative;*/
  /*overflow: hidden;*/
}
.el-row >>> .avatar-uploader .el-upload:hover {
  /*border-color: #409eff;*/
}
.el-row >>> .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 200px;
  height: 200px;
  line-height: 200px;
  text-align: center;
  border: 1px #cccccc dashed;
}
.el-row >>> .avatar {
  width: 200px;
  height: 200px;
  display: block;
}
#short_video{display: none}
.el-progress{ display: none}
</style>